<template>
    <y-panel
        v-if="model"
        class="form-product-tagging"
        :title="$t('market.product_tagging.title')"
    >
        <template
            v-if="element.main_folder_slug && element.has_setting_button"
            slot="functions"
        >
            <y-button
                size="xs"
                tag="router-link"
                target="_blank"
                rel="noopener noreferrer"
                :to="folderLink(element.main_folder_slug)"
            >
                {{ $t('button.manage') }}
            </y-button>
        </template>

        <y-form-field
            ref="tagging-product-search"
            v-model="model.tagging"
            type="select"
            name="tagging-product-search"
            :placeholder="$t('fields.tagging_search')"
            :options="taggingList"
            :endpoint="endpoint"
            label-field="title"
            value-field="id"
            search="search"
            allow-empty
            no-label
            add-including
            class="tagging-search"
            @select="selectParticipants"
        />

        <div class="levels">
            <template v-if="levels && levels.length">
                <div
                    v-for="(labels, level) in levels"
                    :key="`level-${level}`"
                    class="level"
                >
                    <a
                        v-for="label in labels"
                        :key="`label-${label.id}`"
                        href="#"
                        class="button sm inline-block ellipses max-w-100"
                        :class="{active: isActive(label.id)}"
                        @click.prevent="fetchLevel(label, level)"
                    >
                        {{ label.title }}
                    </a>
                </div>
            </template>

            <y-empty-state
                v-else
                class="tagging_empty"
                height="250"
                :message="$t('market.product_tagging.empty_levels')"
            />

            <div
                v-if="$wait.is('category-loading')"
                class="level"
            >
                <y-loading
                    :active="$wait.is('category-loading')"
                    height="40px"
                />
            </div>
        </div>

        <div
            v-if="Object.keys(folders).length"
            class="folders"
        >
            <div class="row sm">
                <div
                    v-for="folder in folders"
                    :key="`option-${folder.id}`"
                    class="col-sm-4 folder"
                >
                    <y-panel
                        :title="folder.title"
                        size="sm"
                    >
                        <template slot="functions">
                            <y-form-field
                                ref="folder-tag"
                                v-model="folderSearchMap[folder.id]"
                                type="text"
                                name="tagging-product-search"
                                :placeholder="$t('fields.tagging_search')"
                                :options="filteredFolderTaggingOptions(folder)"
                                label-field="title"
                                value-field="id"
                                search="search"
                                allow-empty
                                no-label
                                add-including
                                @input="handleSearchChange(folder.id)"
                            />
                            <y-button
                                v-if="element.has_setting_button"
                                size="xs"
                                icon="md-cogs"
                                tag="router-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                :to="folderLink(folder.slug)"
                            />
                        </template>

                        <y-loading
                            :active="$wait.is('folders-loading')"
                            height="250px"
                        >
                            <div
                                v-if="folder.tags && folder.tags.length"
                                class="tags-select"
                            >
                                <label
                                    v-for="tag in renderedFolderTags[folder.id] || []"
                                    :key="`tag-${tag.id}`"
                                    :for="`tag-${tag.id}`"
                                    class="checkbox-label"
                                >
                                    <input
                                        :id="`tag-${tag.id}`"
                                        v-model="model.options[tag.id]"
                                        type="checkbox"
                                        :name="`tag-${tag.id}`"
                                    >
                                    {{ tag.title }}
                                </label>
                                <div
                                    v-if="folderRenderLoading[folder.id]"
                                    class="loading-wrapper"
                                >
                                    <y-loading
                                        :active="true"
                                        height="40px"
                                    />
                                </div>
                            </div>
                            <y-empty-state
                                v-else
                                no-image
                                height="230"
                                :message="$t('market.product_tagging.empty_option')"
                            />
                        </y-loading>
                    </y-panel>
                </div>
            </div>
        </div>
    </y-panel>
</template>

<script>

    export default {

        name: 'YFormProductTagging',

        components: {
            YFormField : () => import('@deps/form/FormField'),
            YEmptyState: () => import('@deps/EmptyState'),
        },

        inject: {
            $validator: '$validator',
        },

        props: {
            value  : Object,
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || null,

                folders            : {},
                levels             : [],
                search             : null,
                taggingQuery       : null,
                taggingListResource: [],
                folderSearchMap    : {},
                renderedFolderTags : {},
                folderRenderLoading: {},
            };
        },

        computed: {
            /**
             * Search Ware
             */
            endpoint() {
                return {
                    name  : 'tagging-labels',
                    params: {
                        search   : this.taggingQuery,
                        including: ['id', 'title', 'children_count', 'attached_folders', 'parent', 'titles'],
                        slug     : this.element.main_folder_slug,
                        limit    : 99,
                    },
                };
            },

            /**
             * get tagging list
             */
            taggingList() {
                return this.model.tagging && this.model.tagging.length ? this.taggingListResource : [];
            },

            /**
             * New computed property for folder options filtered by search
             */
            filteredFolderTaggingOptions() {
                return (folder) => {
                    const searchValue = this.folderSearchMap[folder.id] || '';
                    return folder.tags?.filter((tag) => tag.title.toLowerCase().includes(searchValue.toLowerCase())) || [];
                };
            },
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

        /**
         * @inheritDoc
         */
        async created() {
            await this.fetch();

            if (this.model && this.model.categories && this.get(this.model, 'categories', []).length) {
                const prs = this.model.categories.map((category, index) => this.fetch(category, index + 1, true));
                await Promise.all(prs);
                const label = this.levels[this.levels.length - 1].find((el) => el.id === this.model.categories[this.model.categories.length - 1]);
                if (label && label.attached_folders && label.attached_folders.length) {
                    this.fetchFolders(label.attached_folders);
                }

                if (this.get(this.model, 'selected', [])) {
                    this.$set(this.model, 'options', this.model.selected.reduce((acc, val) => {
                        acc[val] = 1;
                        return acc;
                    }, {}));
                }
            } else {
                this.$set(this, 'model', {});
                this.$set(this.model, 'categories', []);
                this.$set(this.model, 'options', {});
            }
        },

        methods: {

            /**
             *
             * @param folderId
             * @param allTags
             * @param chunkSize
             */
            chunkRenderFolderTags(folderId, allTags, chunkSize = 5) {
                this.$set(this.renderedFolderTags, folderId, []);
                this.$set(this.folderRenderLoading, folderId, true);

                /**
                 *
                 * @param index
                 */
                const renderChunk = (index) => {
                    const chunk = allTags.slice(index, index + chunkSize);
                    this.renderedFolderTags[folderId].push(...chunk);
                    if (index + chunkSize < allTags.length) {
                        requestIdleCallback(() => renderChunk(index + chunkSize));
                    } else {
                        this.$set(this.folderRenderLoading, folderId, false);
                    }
                };

                requestIdleCallback(() => renderChunk(0));
            },
            /**
             * Handle input change for search
             *
             * @param {string} folderId - The id of the folder being searched
             */
            handleSearchChange(folderId) {
                const searchValue = this.folderSearchMap[folderId] || '';
                this.$set(this.folderSearchMap, folderId, searchValue);
            },
            /**
             * Select User from the result
             *
             * @param {object} result - selected user
             */
            selectParticipants(result) {
                if (!this.model.tagging) {
                    this.model.tagging = [];
                }
                if (this.model.tagging) {
                    this.taggingListResource.push({ id: this.model.tagging });
                    this.model.tagging.push(result.id);
                }
                this.model.categories = [];
                this.fetchLevel(result, 0, true);
                this.model.tagging = [];
                this.taggingListResource = [];

                this.$set(this, 'folders', {});
                this.$set(this.model, 'options', {});
            },
 
            /**
             * Handle fetch level
             *
             * @param label
             * @param level
             * @param search
             */
            async fetchLevel(label, level, search = false) {
                let target = level + 1;

                this.levels.splice(level + 1);


                this.$set(this, 'folders', {});
                this.$set(this.model, 'options', {});

                if (search) {
                    let _step = 0;
                    if (label.parent?.parent?.id) {
                        this.model.categories.splice(level + _step, this.model.categories.length - level + _step, label.parent.parent.id);
                        await this.fetch(label.parent.parent.id, target);
                        target += 1;
                        _step += 1;
                    }
                    if (label.parent?.id) {
                        this.model.categories.splice(level + _step, this.model.categories.length - level + _step, label.parent.id);
                        await this.fetch(label.parent.id, target);
                        target += 1;
                        _step += 1;
                    }

                    if (label.id) {
                        this.model.categories.splice(level + _step, this.model.categories.length - level + _step, label.id);
                        await this.fetch(label.id, target);
                        target += 1;
                        _step += 1;
                    }
                } if (!search) {
                    this.model.categories.splice(level, this.model.categories.length - level, label.id);


                    if (label.children_count > 0) {
                        return this.fetch(label.id, target);
                    }
                    if (label && label.attached_folders && label.attached_folders.length) {
                        return this.fetchFolders(label.attached_folders);
                    }
                }



                return false;
            },

            /**
             * Fetch categories
             *
             * @param parent_id
             * @param level
             * @param init
             */
            fetch(parent_id = 0, level = 0, init = false) {
                this.$wait.start('category-loading');
                const params = {
                    slug     : this.element.main_folder_slug,
                    including: ['title', 'children_count', 'attached_folders'],
                    parent_id,
                };
                return this.$services.Tagging.labels(params).then((response) => {
                    if (response.data.results && response.data.results.length) {
                        this.$set(this.levels, level, response.data.results);

                        if (!init && this.levels.length > level + 1) {
                            this.$set(this, 'levels', this.levels.slice(level + 1));
                        }
                    }
                }).catch((error) => error).finally(() => this.$wait.end('category-loading'));
            },

            /**
             * Fetch folders
             *
             * @param folders
             */
            fetchFolders(folders) {
                this.$wait.start('folders-loading');

                this.folders = folders.reduce((acc, el) => {
                    acc[el.id] = el;
                    return acc;
                }, {});

                const prs = folders.map((folder) => this.$services.Tagging.labels({
                    slug     : folder.slug,
                    including: ['title'],
                }));

                return Promise.all(prs).then((responses) => {
                    responses.forEach((response) => {
                        const id = this.get(response, 'data.metadata.folder.id');
                        this.folders[id].tags = Object.freeze(response.data.results);
                        this.chunkRenderFolderTags(id, response.data.results);
                    });
                }).catch((error) => error).finally(() => this.$wait.end('folders-loading'));
            },

            /**
             * Create folder manage link
             *
             * @param slug
             */
            folderLink(slug) {
                return {
                    name  : 'content-tagging-manage',
                    params: { slug },
                };
            },

            /**
             * Check if category is active
             *
             * @param id
             */
            isActive(id) {
                if (!this.model.categories) {
                    return false;
                }
                return this.get(this.model, 'categories').includes(id);
            },

        },

    };

</script>

<style lang="scss" scoped>
  .ellipses {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .inline-block {
    display: inline-block;
  }

  .max-w-100 {
    max-width: 100%;
  }

  .search-subtitle{
    color:#AAA;
    font-size: 10px;
  }
  .search-title{
    color: #58666E;
    font-size: 14px;
  }
  .search-li:hover {
    .search-subtitle,.search-title {
      color: #1875F0;
    }
  }
  .mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .checkbox-label {
    color: #000;

  }

</style>
